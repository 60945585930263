<template>
    <div class="testing-title" v-show="isDetect">No Domain Needs to be Detected </div>   
    <domainResult
        :domainData="domainData"
        v-show="!isDetect"
        :dLoading="dLoad"
    >
    </domainResult>
</template>
<script>


import { apiDomain } from '../basic_info/basic';
import domainResult from './result.vue'
import axios from 'axios';
export default({
    name:'domainStatus',
    components:{domainResult},
    data(){
        return{
            domainData:[],
            isDetect:false,
            dLoad:false,
            tempIp:{}
        }
    },
    created(){
        this.fetchDomain()
    },
    methods:{
        async fetchDomain(){
            this.dLoad=true
            let url =apiDomain+'getdomains'
            const {data,status} = await axios.get(url,{timeout:10000})
            if(status===200){
                let res =data.data
                if(res.length===0){
                    console.log("fetch array is empty")
                }else{
                    await this.domainDectect(res)    
                }     
                this.dLoad=false
            }else{
                console.error("fetch geoinfo error",status)
                this.dLoad=false
            }
        },
        async getData(dArr){
            const that= this;
            //设置整体请求的超时时间为20s
            const overallTimeout =20000;

            //创建一个Promise,用于控制整体请求的超时
            const overallTimeoutPromise = new Promise((resolve,reject)=>{
                setTimeout(()=>{
                    reject(new Error('Overall timeout'));
                },overallTimeout);
            });

            //使用Promise.race()来同时等待和整体超时
            const promises = dArr.map((domain)=>{
                return Promise.race([new Promise((resolve,reject)=>{
                    try{
                        const xhr = new XMLHttpRequest();
                        const start = performance.now();
                        
                        xhr.onload = async function(){
                            const end = performance.now();
                            const getTime = end -start;
                            await that.getDomainIp(domain);

                            that.domainData.push({
                                name:domain,
                                status:xhr.status >=200 && xhr.status <=400?"Yes":"No",
                                ip:that.tempIp[domain],
                                resTime:getTime.toFixed(2),
                                code:xhr.status
                            });
                            resolve();
                        };
                        xhr.onerror = function(){
                            const end = performance.now();
                            that.domainData.push({
                                name:domain,
                                status:"No",
                                ip:'N/A',
                                resTime:end-start,
                                code:xhr.status
                            });
                            resolve();
                        };
                        xhr.open("GET",`https://${domain}`,false);
                        xhr.send();
                    }catch(error){
                        console.error("发生错误",error);
                        that.domainData.push({
                            name:domain,
                            status:"No",
                            ip:'N/A',
                            resTime:0,
                            code:-1
                        });
                        resolve();
                    }
                }),
                overallTimeoutPromise
            ]);
            });
            await Promise.all(promises);
        },
        async domainDectect(dArr) {
            await this.getData(dArr);
            if (this.domainData.length === 0) {
                this.isDetect = true;
            }    
        },
        async getDomainIp(domain){
            try{
                const resp = await axios.get(`https://dns.google.com/resolve?name=${domain}`,{crossDomain: true});
                if(resp.status===200){
                    //如果NOERROR (0)：成功解析域名。其余状态不用解析answer
                    if(resp.data.Status===0){
                        let answer = resp.data.Answer
                        for(let an of answer){
                            if(an.type===1||an.type===28){
                                this.tempIp[domain]=an.data;
                                break;
                            }
                        }
                    }else{
                        this.tempIp[domain]= 'N/A'
                        console.error("google dns error,response code is ",resp.data.Status)
                    }  
                }
            }catch(error){
                console.error("getDomainIp error",error)
                this.tempIp[domain]= 'N/A'
            }
        },
        async getDomainByipify(domain){
            try{
                const resp = await axios.get(`https://api.ipify.org?format=json&hostname=${domain}`, 
                 {crossDomain: true})
                if(resp.status ===200){
                    this.tempIp[domain]=resp.data.ip
                }else{
                    this.tempIp[domain]= 'N/A'
                }
            }catch(error){
                console.log("getDomainIp error",error)
                this.tempIp[domain]= 'N/A'
            }
        }   
    }
})
</script>

<style scope>
.testing-title{
  margin-top:10%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  font-weight: bolder;
  font-size: x-large;
 
}
.select-row{
    margin-top:5%;
    margin-left:5%;
    margin-right:8%;
}
@media screen and (max-width:800px){
    .el-table{
        font-size:8px !important;
        

    }
}
</style>