<template>
<div class="back">
    <img src="/mms_detect/image/return.png" class="back-image" alt="Back" @click="goBack">
</div>    
<div class="finish-page">
    <img src="/mms_detect/image/cheers.png" alt="Cheers" class="finish-image">
    <p class="descri">{{finishDescri  }}</p>
</div>
</template>

<script>
import { mapMutations } from 'vuex';
import router from '@/router';

export default ({
    name:'finishPage',
    data(){
        return{
            finishDescri:'Congratulations! You have finished environment detection!'
        }
    },
    methods:{
        ...mapMutations(['setActiveIndex']),
        goBack(){
            this.$store.commit('setActiveIndex', 5); 
            router.push('/mms_detect/detect');           
        }
    }
})
</script>
<style scoped>
.back{
  position: fixed;
  top: 80px;
  left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 40px; */
}
.back-image{
    width:30px;
    height:30px;
}
.back-image:hover {
  cursor: pointer;
}
.finish-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.finish-image{
    width:18%;
    height: auto;
    margin-top:8%;
}
.descri{ 
    color: #fa4a30;
    font-size: 20px;
    font-weight:bolder ;
}

@media screen and (max-width:900px){
.finish-image{
    width:18%;
    height: auto;
    margin-top:50%;
}
.descri{
    font-size: 16px;
}
}
</style>
