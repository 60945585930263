<template>
    <el-card shadow="always" class="video-class">
        <el-descriptions title="Image Codec" :column="isM?'1':'2'" border>
            <el-descriptions-item v-for="(val,key,index) in imageResult" :key="index" :label="key" >
                <el-button  class ="tag-button" :type="val?'success':'danger'" plain :loading="isLoading[key]">
                    {{  isLoading[key] ? 'Loading' : (val ? 'Support' : 'Unsupport')  }}
                </el-button>
            </el-descriptions-item>
        </el-descriptions>
    </el-card>
</template>

<script>
import { imgUrl,checkImageDecode } from './codec';
// import { checkImageDecode } from './codec';

export default ({
    name:'imageCodec',
    data(){
        return{
            isLoading: {},
            imageResult:{
                // "JPEG Encode":false,
                "JPG Decode":false,
                // "PNG Encode":false,
                "PNG Decode":false,
                // "GIF Encode":false,
                "GIF Decode":false,
                // "WEBP Encode":false,
                "WEBP Decode":false,
            }
        }
    },
    props:{
        isM:Boolean,
    },
    created(){
        this.checkDecodeImages()      
    },
    methods:{
        async checkDecodeImages(){
            const that=this
            for(let key in imgUrl){
                try {
                    that.isLoading[key + " Decode"]=true; // 设置按钮的加载状态为 true
                    const isOK = await checkImageDecode(imgUrl[key]);
                    that.imageResult[key + " Decode"] = isOK;
                    // console.log("浏览器支持图片编解码", isOK, key);
                } catch (error) {
                    console.log("浏览器不支持图片解码", error);
                }finally {
                    that.isLoading[key + " Decode"]=false; // 设置按钮的加载状态为 false
                }
            }
        },
    }
})
</script>
<style scope>
.video-class{
    margin-top:5%;
    margin-left:8%;
    margin-right:8%;
}
@media screen and (max-width: 800px){
    .el-card{
        max-width:100%
    }
    .el-descriptions__header .el-descriptions__title {
        font-size:14px !important;
    }
    .el-descriptions__body  .el-descriptions__table .el-descriptions__cell{
        padding: 2px 3px !important;
        font-size:10px !important;
        line-height: 16px !important;
    } 
    .tag-button {
        font-size: 8px !important;
        padding: 5px !important;
        height: 20px !important;
        width: 50px !important;
    }
}
</style>