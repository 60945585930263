<template>
  <div class="testing-report">
    <div class="testing-title">Detect Report</div>
    <div class="report-list">
        <div class="detect-report">
            <div class="detect-info">
                <span class="report-icon" v-html="infoSVG"></span>
                <span class="item-name">Basic Info</span>
              <div class="details">
                <el-descriptions border class="description" :column="isM?'1':'3'">
                  <el-descriptions-item v-for="(val,key,index) in bInfo" :key="index" :label="key" >
                    {{ val }}
                  </el-descriptions-item>
                </el-descriptions>
              </div>    
            </div>      
            <div class="detect-info">
                <span class="report-icon" v-html="videoSVG"></span>
                <span class="item-name">Vod Codec</span>
                  <div class="details">
                    <el-descriptions border class="description" :column="isM?'1':'3'">
                      <el-descriptions-item v-for="(val,key,index) in vInfo" :key="index" :label="key" >
                        <el-button class="tag-button" v-if="val" type="success" plain>Support</el-button >
                        <el-button  class="tag-button" v-else type="danger">Unsupport</el-button>
                      </el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
            <div class="detect-info">
                <span class="report-icon" v-html="imageSVG"></span>
                <span class="item-name">Image Codec</span>
                    <div class="details">
                      <el-descriptions border class="description" :column="isM?'1':'2'">
                        <el-descriptions-item v-for="(val,key,index) in iInfo" :key="index" :label="key" >
                          <el-button class="tag-button" v-if="val" type="success" plain>Support</el-button >
                          <el-button  class="tag-button" v-else type="danger">Unsupport</el-button>
                        </el-descriptions-item>
                      </el-descriptions>
                  </div>
            </div>
            <div class="detect-info">
                <span class="report-icon" v-html="networkSVG"></span>
                <span class="item-name">Domain</span>
                    <div class="details">
                      <el-table
                        :data=dData
                        stript
                        class="result-table"
                        border
                      >
                        <el-table-column label="#" :min-width="isM?'8%':'10%'" align="center">
                            <template  #default="scope">
                            <span>{{ scope.$index+1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="Domain" min-width="43%"></el-table-column>
                        <el-table-column prop="status" label="Reachable" :min-width="isM?'15%':'22%'"></el-table-column>
                        <el-table-column prop="code" label="Status" min-width="15%"></el-table-column>
                        <el-table-column prop="ip" label="IP" min-width="32%"></el-table-column>
                        <el-table-column prop="resTime" label="DNS Time (ms)" :min-width="isM?'20%':'43%'"></el-table-column>
                      </el-table>
                  </div>
            </div>
        </div>
    </div>
  </div> 
</template>

<script>
import { InfoIcon, VideoIcon, ImageIcon, NetworkIcon } from './utils.js';
import { useStore } from 'vuex';
import { computed } from 'vue';


export default {
    name: "testReport",
    props:{
        isM:Boolean,
    },
    setup() {
        const store = useStore();
        const getGlobalData = computed(() => store.state.globalResult);
        return {
            infoSVG: InfoIcon,
            videoSVG: VideoIcon,
            imageSVG: ImageIcon,
            networkSVG: NetworkIcon,
            getGlobalData,
            bInfo: getGlobalData.value["basic_info"],
            vInfo: getGlobalData.value["video_codec"],
            iInfo:getGlobalData.value["image_codec"],
            dData:getGlobalData.value["domain"],
        };
    },
}
</script>
<style scope>
.testing-report {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.testing-title {
  display: flex;
  font-size: 34px;
  justify-content: center;
  margin-top: 30px;
  color: #201e1ee5;
}

.testing-report .report-list {
  display: block;
  width: 100%;
  margin-top: 10px;
}

.testing-report .report-list .detect-report {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  margin: 20px auto 0;
  justify-content: space-between;
}

.testing-report .report-list .detect-report .detect-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.testing-report .report-list .detect-report .detect-info .report-icon {
  margin-right: 20px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  font-size: 22px;
  line-height: 22px;
  color: #515151;
}

.testing-report .report-list .detect-report .detect-info .report-icon svg {
  width: 24px;
  height: 24px;
}

.testing-report .report-list .detect-report .detect-info .item-name {
  /* flex-basis: calc(70% - 20px); */
  /* width: 280px; */
  height: 24px;
  line-height: 24px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight:bolder;
}

.testing-report .report-list .detect-report .detect-info .details {
  margin-top: 5%;
  width: 150%;
  margin-bottom: 5%;
  justify-content: center;
  /* flex-basis: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%; */
}

.testing-report .report-list .detect-report .detect-info .description {
   width: 100%; 
}

.testing-report .report-list .detect-report .green {
  color: green;
}

.testing-report .report-list .detect-report .red {
  color: red;
}
.result-table{
    /* margin-top:5%; */
    margin-left:3%;
    margin-right:3%;
}

@media screen and (max-width: 850px){
  .testing-title{
    font-size: 16px ;
    margin-bottom: 8px ;
  }
  .detect-report{
    width: 100%;
    margin: 8px auto 0;
  }
  .testing-report .report-list .detect-report .detect-info .report-icon{
    margin-right: 10px;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
  }
  .testing-report .report-list .detect-report .detect-info .report-icon svg{
    width: 18px;
    height: 18px;
  }
  .testing-report .report-list .detect-report .detect-info .details {
    margin-top: 40px ;
    margin-bottom: 40px ;
  }
  .testing-report .report-list .detect-report .detect-info .item-name{
    flex-basis: 80px;
    font-size: 12px;
    line-height: 12px;
  }
  .el-table{
        font-size:6px !important;
  }
  .el-table .cell{
        padding: 0 1px !important;
  }
  .testing-report .report-list .detect-report .detect-info .details .result-table{
    margin-top:5px;
    display: flex;
    /* max-width: 120%; */
    width: 280px;
    margin-left:0px;
  }
 
  }
</style>