<template>
    <div ref="basicData">
        <el-card shadow="always" class="basic-card">
            <el-descriptions title="Basic Info" :column="isM?'1':'3'" border>
                <el-descriptions-item label="OS">{{UAResult.os.name}}</el-descriptions-item>
                <el-descriptions-item label="Device(Model,Vendor)">{{UAResult.device.model}},{{UAResult.device.vendor}}</el-descriptions-item>
                <el-descriptions-item label="Resolution">{{res}}</el-descriptions-item>
                <el-descriptions-item label="Browser Name">{{UAResult.browser.name}}</el-descriptions-item>
                <el-descriptions-item label="Browser Version">{{UAResult.browser.version}}</el-descriptions-item> 
                <el-descriptions-item label="Render Engine">{{UAResult.engine.name}}</el-descriptions-item>
                <el-descriptions-item label="IP">{{GeoInfo.ip}}</el-descriptions-item>
                <el-descriptions-item label="Province">{{GeoInfo.user_province}}</el-descriptions-item>
                <el-descriptions-item label="ISP">{{GeoInfo.user_isp}}</el-descriptions-item>
                <el-descriptions-item label="Longitude">{{GeoInfo.longitude}}</el-descriptions-item>
                <el-descriptions-item label="Latitude">{{GeoInfo.latitude}}</el-descriptions-item>
                <el-descriptions-item label="UA">{{UAResult.ua}}</el-descriptions-item>
            </el-descriptions>
        </el-card>
    </div>
</template>

<script>
import {uaResult,resolution,apiDomain}  from './basic.js'
import axios from 'axios';

export default ({
    name:'basicInfo',
    data(){
        return{
            UAResult:uaResult,
            res:resolution,
            GeoInfo:{
                ip:'',//用户IP
                user_province:'',//用户所在省份
                user_isp:'',//用户所在运营商
                longitude:'',//经度
                latitude:'' //纬度
            }
        }
    },
    props:{
        isM:Boolean,
    },
    created(){
        this.getGeoInfo()
    },
    methods:{
       async getGeoInfo(){
            let  url = apiDomain+'geoinfo'
            const {data,status} = await axios.get(url,{timeout:10000})
            if(status===200){
                this.GeoInfo =data.data
            }else{
                console.error("fetch geoinfo error",status)
            }
        },   
        getBasicData(){   
            return {
                'OS':uaResult.os.name,
                'Device(Model,Vendor)':uaResult.device.model+','+uaResult.device.vendor,
                'Resolution':this.res,
                'Browser Name':uaResult.browser.name,
                'Browser Version':uaResult.browser.version,
                'Render Engine':uaResult.engine.name,
                'IP':this.GeoInfo.ip,
                "Province":this.GeoInfo.user_province,
                "ISP":this.GeoInfo.user_isp,
                "Longitude":this.GeoInfo.longitude,
                "Latitude":this.GeoInfo.latitude,
                'UA':uaResult.ua
            
            }
        }
    }
})
</script>
<style>
.basic-card{
    margin-left:8%;
    margin-right:8%;
    margin-top:5%;
      /* 移动端样式 */
  @media (max-width: 900px) {
    margin-left:1%;
    margin-right:1%;
  }
}

@media screen and (max-width:900px){
    .el-card{
        max-width:100%
    }
    .el-descriptions__header .el-descriptions__title {
        font-size:14px !important;
    }
    .el-descriptions__body  .el-descriptions__table .el-descriptions__cell{
        padding: 2px 3px !important;
        font-size:10px !important;
        line-height: 16px !important;
    }    
}

</style>
