<template>
    <div class="button-container">
        <el-button v-if="index>1&&index<5" @click="handleLastStep()" type="primary">Last Detect</el-button>  
        <el-button v-if="index<5" @click="handleNextStep()"  type="primary">Next Detect</el-button>
        <el-button v-if="index===5" @click="handReport()"  type="primary">Finished</el-button>
    </div>
</template>

<script>
export default ({
    name:'changeStep',
    props:{
        index:Number,
    },
    data(){
      return{

      }  
    },
    methods:{
        handleLastStep(){
            this.$emit('handleLastStep')
        },
        handleNextStep(){
            this.$emit('handleNextStep')
        },
        handReport(){
            this.$emit('handReport')
        }
    }
})
</script>

<style scoped>
.button-container{
  margin-top:8%;
  display: flex;
  justify-content: flex-end;
}

</style>