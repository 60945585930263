<template>
    <el-card shadow="always" class="video-class" ref="videoData">
        <el-descriptions title="Video Codec" :column="isM?'1':'3'" border>
            <el-descriptions-item v-for="(val,key,index) in codecResult" :key="index" :label="key" >
                <el-button class ="tag-button" v-if="val" type="success" plain>Support</el-button >
                <el-button class ="tag-button" v-else type="danger">Unsupport</el-button>
            </el-descriptions-item>
        </el-descriptions>
    </el-card>

</template>

<script>
import { DecodeResult,VodEncode } from './codec'


export default({
    name:'videoCodec',
    data(){
        return{  
            codecResult:{

            },
        }
    },
    props:{
        isM:Boolean,
    },
    created(){
        this.codecResult = Object.assign(this.codecResult,DecodeResult)
        
        this.codecResult = Object.assign(this.codecResult,VodEncode)
        
    },
    methods:{
        
    }
})
</script>
<style scope>
.video-class{
    margin-top:5%;
    margin-left:8%;
    margin-right:8%;
}
@media screen and (max-width: 800px){
    .el-card{
        max-width:100%
    }
    .el-descriptions__header .el-descriptions__title {
        font-size:14px !important;
    }
    .el-descriptions__body  .el-descriptions__table .el-descriptions__cell{
        padding: 2px 3px !important;
        font-size:10px !important;
        line-height: 16px !important;
    } 
    .tag-button {
        font-size: 8px !important;
        padding: 5px !important;
        height: 20px !important;
        width: 50px !important;
    }
}
</style>
