<template>
    <el-table
        :data="domainData"
        stript
        class="result-table"
        border 
        v-loading="dLoading"
    >
        <el-table-column label="#" :min-width="isM?'5%':'8%'" align="center">
            <template  #default="scope">
            <span>{{ scope.$index+1}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="name" label="Domain" :min-width="isM?'20%':'35%'"></el-table-column>
        <el-table-column prop="status" label="Reachable" :min-width="isM?'15%':'10%'"></el-table-column>
        <el-table-column prop="code" label="Status Code" :min-width="isM?'15%':'10%'"></el-table-column>
        <el-table-column prop="ip" label="IP" min-width="20%"></el-table-column>
        <el-table-column prop="resTime" label="DNS Time(ms)" min-width="12%"></el-table-column>
    </el-table>
</template>

<script>
export default ({
    name:'domainResult',
    props:{
        domainData:{
            type:Array,
            default:()=>{
                return []
            }
        },
        dLoading:{
            type:Boolean,
            default:()=>{
                return false
            }
        },
        props:{
            isM:Boolean,
        },
    },
    data() {
      return{
       
      }  
    },
})
</script>
<style scope>
.result-table{
    margin-top:5%;
    margin-left:8%;
    margin-right:8%;
    width:auto;
}

@media screen and (max-width:900px){
    .result-table{   
        max-width: 95%;
     }     
    .el-table{
        font-size:6px !important;
    }
    .el-table .cell{
        padding: 0 0px !important;
    }
}
</style>