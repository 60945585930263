import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from './views/step_card/global'
import App from './App.vue'
import router from './router'
const app = createApp(App)
app.use(ElementPlus)
app.use(store)
app.use(router)
app.mount('#app')


/**
 * 
 * //实例化FFmpeg
const ffmpeg = createFFmpeg({
    log:true,
    // corePath:'/Users/siwenyu/mms_detector/src/assets/@ffmpeg/core/dist/ffmpeg-core.js',
    wasmPath:wasmPath,
})
 * / 在适当的时机加载 FFmpeg，例如在应用程序挂载之前
app.mixin({
    async beforeMount() {
      await ffmpeg.load()  // 加载 FFmpeg
      console.log("isloaded",ffmpeg.isLoaded())
    }
  })

app.config.globalProperties.ffmpeg = ffmpeg // 将 ffmpeg 实例绑定到全局属性中，以便组件中使用  

 */


