import UAParser from "ua-parser-js";

export const uaResult = parseUa()
export const resolution=getResolution()


function parseUa(){
    var up = UAParser()
    return up
}

/**
 * 
 * @returns 获取屏幕分辨率
 */
function getResolution(){
    var height = window.screen.height
    var width = window.screen.width
    if(height && width){
        return height.toString()+'*'+width.toString()
    }
    return ''
}

/**
 * 获取用户IP等信息
 */

export const apiDomain = "https://endpoint.mms.test.shopee.ph/api/v1/detect/"
export const reportConfig = {
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 8000
  }



