import {createRouter,createWebHistory} from 'vue-router'

import finishPage from '@/views/finishPage/finishPage.vue';
import mmsDetect from '@/views/step_card/steps.vue';

const routes =[{
        path:'/mms_detect',
        redirect:'/mms_detect/detect',
    },{
        path:'/mms_detect/detect',
        name:'Detect',
        component:mmsDetect,
        props:{
            activeIndex:'index'
        }
    },{
        path:'/mms_detect/finish',
        name:'Finish',
        component:finishPage
    }
]


const router = createRouter({
    history:createWebHistory(),
    routes
})

export default router;