<template>
    <el-steps :active="activeIndex" finish-status="success"  align-center v-if="isMobile" style="margin-bottom: 30px;">
        <el-step 
            v-for="(item,index) of stepTitle" 
            :key="index"
            :title="item"
        />
    </el-steps>
    <el-steps :active="activeIndex" finish-status="success" simple class="step-class" v-else>
        <el-step 
            v-for="(item,index) of stepTitle" 
            :key="index"
            :title="item"
        />
    </el-steps>
    <basicInfo v-if="activeIndex===1" ref="basicData" :isM="isMobile"></basicInfo>
    <videoCodec v-if="activeIndex===2" ref="videoData" :isM="isMobile"></videoCodec>
    <imageCodec v-if="activeIndex===3" ref="imageData" :isM="isMobile"></imageCodec>
    <domainStatus v-if="activeIndex===4" ref="domainRef" :isM="isMobile"></domainStatus>
    <testReport v-if="activeIndex===5" :isM="isMobile"></testReport>

    <changeStep
      :index="activeIndex"
      @handleNextStep="handNext"
      @handleLastStep="handLast"
      @handReport="handReportBE"
    ></changeStep>
</template>
<script>
/* eslint-disable */ 
import changeStep from './changeStep.vue'
import basicInfo from '@/views/basic_info/basic_info.vue'
import videoCodec from '@/views/codec/videoCodec.vue'
import imageCodec from '@/views/codec/imageCodec.vue'
import domainStatus from '@/views/cdn/domain.vue'
import testReport from '@/views/report/testReport.vue'
import {mapState,mapMutations} from 'vuex'
import { apiDomain,reportConfig } from '../basic_info/basic'
import axios from 'axios'
import router from '@/router';
import {checkMediaQuery} from './utils'


export default ({
    name:"mmsDetect",
    components:{basicInfo,videoCodec,imageCodec,changeStep,domainStatus,testReport},
    data(){
        return{
            activeIndex:1,
            stepTitle:["Basic Info","Vod Codec","Image Codec","Domain","Report"],
            reportData:{},
            isMobile:false
        }
    },
    computed:{
        ...mapState(['globalResult','activeIndex']),
        activeIndex() {
            return this.$store.state.activeIndex;
        },
    },
    mounted(){
        this.activeIndex = this.$store.state.activeIndex;
        this.isMobile = checkMediaQuery()
        console.log("ismo",this.isMobile )
    },
    methods:{
        ...mapMutations(['setGlobalResult','setActiveIndex']), // 映射更新全局结果状态的mutation
        updateGlobalResult() {
            this.setGlobalResult(this.reportData); // 更新全局结果
        },
        // updateActiveIndex(index){
        //     this.setActiveIndex(index)
        // },
        handLast(){
            if(this.activeIndex>1){
                this.activeIndex--
                // this.updateActiveIndex(this.activeIndex)
                this.$store.commit('setActiveIndex', this.activeIndex);
            }else{
                //提示已经是第一步
            }
            
        },
        handNext(){
            switch(this.activeIndex){
                case 1:
                    this.reportData['basic_info']=this.$refs.basicData.getBasicData();
                    this.updateGlobalResult()
                    break;
                case 2:
                    this.reportData['video_codec']=this.$refs.videoData.codecResult; 
                    this.updateGlobalResult()
                    break;
                case 3:  
                    this.reportData['image_codec']=this.$refs.imageData.imageResult; 
                    this.updateGlobalResult()
                    break;  
                case 4:
                    this.reportData['domain']=this.$refs.domainRef.domainData; 
                    this.updateGlobalResult()
                    break;  
            }
            this.activeIndex++
            // this.updateActiveIndex(this.activeIndex)
            this.$store.commit('setActiveIndex', this.activeIndex);
        },
       handReportBE(){
            let reportUrl =apiDomain+"report"
            axios.post(reportUrl,this.reportData,reportConfig).then(
                // console.log("report",this.reportData)
            ).catch((error)=>{
                console.log("report be error",error)
            })
            router.push('/mms_detect/finish')
        }
    }
})
    
</script>
<style scope>
.step-class{
    margin-left:5%;
    margin-right:5%;
}
@media screen and (max-width:900px){
    .el-step__title {
        font-size:10px;
        font-weight: 700;
    }
}
</style>