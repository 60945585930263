
/**
 * MediaSource.isTypeSupported()判断编解码支持
 */
const h264Codec ='video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
const vp8Codec ='video/webm; codecs="vp8"';
const vp9Codec='video/webm; codecs="vp9"';
const h265Codec='video/mp4;codecs=hvc1.1.6.L93.90';
const aacCodec='audio/mp4; codecs="mp4a.40.2"'
const mp3Codec='audio/mpeg'
function TypeSupported(codec){
    if('MediaSource' in window && MediaSource.isTypeSupported(codec)){
        return true
    }else{
        return false
    }
}

export const DecodeResult =getCodecResult()

function getCodecResult(){
    let result = {
        'H264 Decode':TypeSupported(h264Codec),
        'H265 Decode':TypeSupported(h265Codec),
        'VP9 Decode':TypeSupported(vp9Codec),
        'VP8 Decode':TypeSupported(vp8Codec),
        'AAC Decode':TypeSupported(aacCodec), 
        'MP3 Decode':TypeSupported(mp3Codec),    
    }
    return result
}

/**
 * canPlayType方式
 * @param {*} vType 编解码类型
 * @returns 为true表示支持这种类型
 */
export function canPlayVideo(vType){
    var myVideo =document.createElement('video')
    switch(vType){
      case 'h264':
        if("" != myVideo.canPlayType('video/mp4;codecs="avc1.4D401E, mp4a.40.2"')){      
          return true
        }
        break
      case 'vp8':
        if ("" != myVideo.canPlayType('video/ogg;codecs="vp8"')){
          return true
        } 
        break
      case 'h265':
        if(""!=myVideo.canPlayType('video/mp4; codecs="hevc"')){
          return true
        }
        break
      case 'vp9':
        if(""!=myVideo.canPlayType('video/webm;codecs=vp9"')){
          return true
        }
        break  
    }
}


// export const h265Url = 'https://play-sp.vod.shopee.com/api/v4/11110124/mms/id-11110124-6jt74-lgyjau00qvt843.ori.mp4';
// export const h264Url='https://play-sp.vod.shopee.com/api/v4/11110124/mms/id-11110124-6jt74-lgyjau00qvt843.16003231684464106.mp4';
// export const vp9Url ='https://play-sp.vod.shopee.com/api/v4/11110124/mms/id-11110124-6jt74-lgyjjxof89zg8b.ori.mp4';
// export const vp8Url ='https://play-sp.vod.shopee.com/c3/98934353/103/A3oyOTyZAFj8YL0gEYYAACY.mp4';
// export const imgJPG='http://mmsdev.shopee.io/test_platform/media/image_assert/by_codec/test1.jpeg';
// export const imgPNG='http://mmsdev.shopee.io/test_platform/media/image_assert/by_codec/test2.png';
// export const imgGIF='http://mmsdev.shopee.io/test_platform/media/image_assert/by_codec/test3.gif';
// export const imgWEBP='http://mmsdev.shopee.io/test_platform/media/image_assert/by_codec/test4.webp';
// export const imgHeic='http://mmsdev.shopee.io/test_platform/media/image_assert/by_codec/test5.heic';
// export const imgWEBP='data:image/webp;base64,UklGRiQAAABXRUJQVlA4TA0AAAAvAAAAEImIj8=';
// export const imgJPG='data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAAAAAAAD/2wBDAAoHBwkHBgoJCAkLCwoMDxkQDw4ODx4WFxIZJCAmJSMgIyIoLTkwKCo2KyIjMkQyNjs9QEBAJjBGS0U+Sjk/QD3/2wBDAQsLCw8NDx0QEB09KSMpPT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT09PT3/wAARCAABAAEDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMB';
// export const imgPNG='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAF2AJX+gF45AAAAABJRU5ErkJggg=='
// export const imgGIF='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const imgUrl={
  "JPG":'/mms_detect/image/test1.jpeg',
  "PNG":'/mms_detect/image/test2.png',
  "GIF":'/mms_detect/image/test3.gif',
  "WEBP":'/mms_detect/image/test4.webp',
}


export function checkImageDecode(src) {
  return new Promise((resolve) => {
  const image = new Image();

  image.onload = async () => {
      try {
      await createImageBitmap(image); // 尝试创建 ImageBitmap 对象
      resolve(true); // 成功创建，浏览器支持图片编解码能力
      } catch (error) {
      resolve(false); // 创建失败，浏览器不支持图片编解码能力
      }
  };
  image.onerror = () => {
    resolve(false); // 图片加载失败
  };
  image.src = src; // 设置图片路径
  });
}

/**
 * 编码能力支持
 */

function checkVideoEncode(codec){
  if (MediaRecorder.isTypeSupported(`video/webm; codecs="${codec}"`)) {
    // console.log(`${codec} encoding is supported`);
    return true
  } else {
    // console.log(`${codec} encoding is not supported`);
    return false
  }
}


// function checkAudioEncode(codec){
//   if (MediaRecorder.isTypeSupported(`${codec}`)) {
//     console.log(`${codec} encoding is supported`);
//     return true
//   } else {
//     console.log(`${codec} encoding is not supported`);
//     return false
//   }
// }

function getVodEncode() {
    let encodeResult={
      // 'H265 Encode':MediaRecorder.isTypeSupported('video/mp4; codecs="hev1"'),
      'H264 Encode':checkVideoEncode('h264'),
      'VP9 Encode':checkVideoEncode('vp9'),
      'VP8 Encode':checkVideoEncode('vp8'),
      // 'AAC Encode':MediaRecorder.isTypeSupported('audio/mp4; codecs="aac"'),
      // 'MP3 Encode':checkAudioEncode(mp3Codec)
    }
    return encodeResult
}

export const VodEncode = getVodEncode()


